<template>
  <page-header-wrapper @back="back">
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <a-card :bordered="false">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 2}" :wrapper-col="{ span: 20 }">
          <a-form-model-item label="用户" prop="userList">
            <a-select mode="multiple"  style="width: 90%" v-model="form.userIdList" allowClear>
              <a-select-option v-for="(item, index) in userList" :value="item.id" :key="index">
                {{ item.nickName }}
              </a-select-option>
            </a-select>
            <a-button  @click="openUserSelect" style="text-align: left; width: 10%" >
              选择用户
            </a-button>
          </a-form-model-item>
            <a-form-model-item label="公告类型" prop="noticeType" >
              <a-select placeholder="请选择" v-model="form.noticeType" style="width: 200px;">
                <a-select-option v-for="(d, index) in dict.type['sys_notice_type']" :key="index" :value="parseInt(d.value)">{{
                    d.label }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="状态" prop="status" >
              <a-radio-group v-model="form.bstatus" button-style="solid" @change="changeStatus">
                <a-radio-button v-for="(d, index) in dict.type['sys_notice_status']" :key="index" :value="parseInt(d.value)">{{
                    d.label }}</a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          <a-form-model-item label="标题" prop="noticeTitle">
            <a-input size="large" width="200px" v-model="form.noticeTitle" placeholder="请输入标题" />
          </a-form-model-item>
          <a-form-model-item label="简介" prop="noticeInfo">
            <a-input size="large" v-model="form.noticeInfo" placeholder="请输入简介" />
          </a-form-model-item>
          <a-form-model-item label="公告图片" prop="noticeImg">
            <a-upload
              name="noticeImg"
              listType="picture-card"
              class="avatar-uploader"
              :multiple="false"
              :show-upload-list="false"
              :preview="handlePreview"
              :before-upload="beforeOssUpload"
              :customRequest="coverImgUpload">
              <img
                v-if="form.noticeImg"
                :src="form.noticeImg"
                alt="noticeImg"
                style="height: 102px; width: 102px;"/>
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'"/>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="内容" prop="noticeContent">
            <editor v-model="form.noticeContent" />
          </a-form-model-item>
        </a-form-model>
        <a-space style="margin-left: 8.5%">
          <a-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="submitForm">
            发布
          </a-button>
          <a-button type="dashed" @click="back">
            取消
          </a-button>
        </a-space>
    </a-card>
    <checkbox-user-select-form ref="checkboxUserSelectForm" @userSelect="indexUserSelect"></checkbox-user-select-form>
  </page-header-wrapper>
</template>

<script>

import { getNotice, addNotice, updateNotice } from '@/api/system/notice'
import Editor from '@/components/Editor'
import bus from '@/utils/bus'
import events from '@/components/MultiTab/events'
import {uploadObject, uploadOss} from "@/api/tool/oss";
import checkboxUserSelectForm from "@/views/user/user/modules/CheckboxSelectForm";

export default {
  name: 'NoticeForm',
  components: {
    Editor,
    checkboxUserSelectForm
  },
  dicts: ['sys_notice_status', 'sys_notice_type'],
  data() {
    return {
      userList:[],
      wrapperCol: { span: 48 },
      submitLoading: false,
      total: 0,
      id: undefined,
      formTitle: '',
      loading: '',
      // 表单参数
      form: {
        userIdList: [],
        id: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeInfo: undefined,
        noticeImg: undefined,
        noticeContent: '',
        status: 1,
        bstatus: 1
      },
      rules: {
        noticeType: [{ required: true, message: '公告类型不能为空', trigger: 'change' }],
        noticeTitle: [{ required: true, message: '公告标题不能为空', trigger: 'blur' }],
        noticeContent: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      open: false
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.id = this.$route.params.id
    this.formTitle = this.$route.params.formTitle
    if (this.id) {
      this.handleUpdate(this.id)
    } else {
      this.handleAdd()
    }
  },
  methods: {
    changeStatus(e) {
      let status = e.target.value
      this.form.status = status === '0' ? '1' : '0'
    },
    // 表单重置
    reset() {
      this.form = {
        userIdList: [],
        id: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: '',
        status: 1,
        bstatus: 1
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.formTitle = '添加公告'
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset()
      const noticeId = id
      getNotice(noticeId).then(response => {
        let str = response.data.status
        this.$set(response.data, 'bstatus', str)
        this.form = response.data
        this.formTitle = '修改公告'
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'notice'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'noticeImg', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          // this.submitLoading = true
          if (this.form.id !== undefined) {
            updateNotice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              // 去更新列表页的数据
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addNotice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    back() {
      let pages = this.$store.pages;
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      this.$router.push('/system/notice')
    },
    onClose() {
      this.open = false
    },
    handleSubmit() {
      this.$refs.baseForm.validate(valid => {
        if (valid) {
          this.open = true
        } else {
          return false
        }
      })
    },
    //关闭当前标签页
    openUserSelect () {
      this.$refs.checkboxUserSelectForm.openUser();
    },
    indexUserSelect(record) {
      //去重
      record.forEach(e => {
        if (!this.userList.find(g=>g.id===e.id)) {
          this.userList.push(e);
        }
      });
      //自动选择地址
      this.form.userIdList= this.userList.map(e=>e.id)
      this.form.noticeType = 1;
    },


  }
}
</script>
